
import components from '@/components'
export default {
  name: 'TestVariant',
  components,
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    showContent() {
      return (
        this.blok.preview ||
        ([-1, 0].includes(+this.$store.getters['split-testing/variantId']) &&
          this.blok.control) ||
        +this.blok.VariantPicker.selected ===
          +this.$store.getters['split-testing/variantId'] ||
        (this.$store.getters.inEditor && this.blok.control)
      )
    }
  },
  mounted() {},
  methods: {}
}
